import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import addDays from 'date-fns/addDays'
import DatePicker, {registerLocale} from 'react-datepicker'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import React, {useLayoutEffect, useMemo, useState} from 'react'
import {add, format, getDay, getHours} from 'date-fns'
import fr from 'date-fns/locale/fr'
import {getIn} from 'formik'
import Box from '../../ui/box/Box'
import Select from '../../ui/form/Select'
import useFetch from '../../../hooks/useFetch'
import CommandeAPI from '../../../services/CommandeAPI'
import Field from '../../ui/form/Field'
import Checkbox from '../../ui/form/Checkbox'
import {ucfirst} from "../../../Tools/String";

registerLocale('fr', fr)

export function DeliveryForm({formik, colXs, colSm}) {
  const [{data: moments, isLoading: isLoadingMoments}] = useFetch(
    CommandeAPI.getMomentsJour(),
  )
  const [{data: hours, isLoading: isLoadingHours}] = useFetch(
    CommandeAPI.getHorairesMax(),
  )
  const baseHours = hours;
  const isForeignCountry = useMemo(
    () =>
      formik.values.receiver &&
      (formik.values.receiver.countryLabel !== 'FRANCE' ||
        formik.values.receiver.country !== 'F'),
    [formik.values.receiver.countryLabel, formik.values.receiver.country],
  )
  const delivererInformationsLimit = 250;
  const [delivererInformationsLength, setDelivererInformationsLength] = useState(0);

  function getMourningMaxHours(date) {
    const now = new Date()
    const nowWithoutHours = format(now, 'yyyy-MM-dd')
    const yesterdayWithoutHours = format(add(now, {days: 1}), 'yyyy-MM-dd')
    const dateWithoutHours = format(date, 'yyyy-MM-dd')

    if (dateWithoutHours === nowWithoutHours) {
      date = new Date(date.setHours(now.getHours(), now.getMinutes()))
      const firstHour = parseInt(baseHours.Heures[0], 10)
      const lastHour = parseInt(
        baseHours.Heures[baseHours.Heures.length - 1],
        10,
      )
      hours.Heures = []

      for (let i = firstHour; i <= lastHour; i += 1) {
        if (i > getHours(date) + 4) {
          hours.Heures.push(i < 10 ? `0${i}` : i)
        }
      }
    } else if (dateWithoutHours === yesterdayWithoutHours) {
      hours.Heures = []

      for (let i = 9; i < 19; i += 1) {
        hours.Heures.push(i < 10 ? `0${i}` : i)
      }
    } else {
      hours.Heures = []

      for (let i = 7; i < 19; i += 1) {
        hours.Heures.push(i < 10 ? `0${i}` : i)
      }
    }

    // Change l'heure si elle n'existe plus dans les propositions en fonction de la date choisie
    if (
      formik.values.delivery.hour !== '' &&
      !hours.Heures.includes(parseInt(formik.values.delivery.hour, 10))
    ) {
      formik.setFieldValue('delivery.hour', hours.Heures[0])
    }

    if (formik.values.occasion === 5) {
      formik.setFieldValue('delivery.hour', hours.Heures[0])
      formik.setFieldValue('delivery.minutes', '00')
    }
  }

  const disabledSundayIfForeignCountry = (date) => {
    const day = getDay(date)
    const arrayDays = [0, 1, 2, 3, 4, 5, 6]

    if (isForeignCountry) {
      delete arrayDays[0]
    }

    return arrayDays.includes(day)
  }

  useLayoutEffect(() => {
    const {dateStart} = formik.values.delivery

    if (
      dateStart instanceof Date &&
      dateStart.getDate() === new Date().getDate() &&
      dateStart.getHours() >= 17
    ) {
      formik.setFieldValue('delivery.dateStart', addDays(dateStart, 1))
    }
  })

  return (
    <>
      <Box
        boxXs={colXs}
        boxSm={colSm}
        boxName={'delivery'}
        headerClassNames={'rounded-top'}
        header={true}
        headerTextColor={'white'}
        headerContent={<h3 className="my-auto">Livraison</h3>}
        headerPadding={'p-3'}
        boxContentPadding={'p-3'}
        content={
          <>
            {formik.values.occasion !== '5' && (
              <Row>
                <Col>
                  <Checkbox
                    id={'delivery.workplace'}
                    name={'delivery.workplace'}
                    type={'checkbox'}
                    label={'Livraison sur le lieu de travail'}
                    className={'mb-2'}
                    checked={formik.values.delivery.workplace}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Date de livraison<span className="text-red"> *</span>
                  </Form.Label>
                  <DatePicker
                    name={'delivery.date'}
                    required={true}
                    openToDate={formik.values.delivery.dateStart}
                    minDate={formik.values.delivery.dateStart}
                    maxDate={formik.values.delivery.dateEnd}
                    dateFormat={'dd/MM/yyyy'}
                    locale={'fr'}
                    placeholderText={'--/--/----'}
                    showLeadingZeros={true}
                    calendarIcon={null}
                    selected={formik.values.delivery.date}
                    filterDate={disabledSundayIfForeignCountry}
                    calendarStartDay={1}
                    className={`form-control w-100 ${
                      getIn(formik.touched, 'delivery.date') &&
                      getIn(formik.errors, 'delivery.date') &&
                      getIn(formik.errors, 'delivery.date') !== ''
                        ? 'is-invalid'
                        : ''
                    }`}
                    disabledKeyboardNavigation
                    onChange={(value) => {
                      formik.setFieldValue('delivery.date', value)
                      getMourningMaxHours(value)
                    }}
                    disabled={!formik.values.scompo.hasAvailableDates}
                    readOnly={!formik.values.scompo.hasAvailableDates}
                  />
                  {getIn(formik.touched, 'delivery.date') &&
                    getIn(formik.errors, 'delivery.date') &&
                    getIn(formik.errors, 'delivery.date') !== '' && (
                      <div className={'text-danger'}>
                        {formik.values.scompo.hasAvailableDates
                          ? formik.errors.delivery.date
                          : '* Ce champ est requis'}
                      </div>
                    )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Select
                  controlId={'delivery.moment'}
                  label={'Moment'}
                  labelRequired={true}
                  name={'delivery.moment'}
                  value={formik.values.delivery.moment}
                  onChange={(event) => {
                    formik.setFieldValue('delivery.moment', event.target.value)
                    formik.setFieldValue(
                      'delivery.momentLabel',
                      event.target.options[event.target.selectedIndex].text,
                    )
                  }}
                  options={
                    (!isLoadingMoments &&
                      moments.map((item, index) => (
                        <option key={index} value={item.momentjourIdx}>
                          {ucfirst(item.label)}
                        </option>
                      ))) ||
                    []
                  }
                  error={
                    getIn(formik.touched, 'delivery.moment') &&
                    getIn(formik.errors, 'delivery.moment')
                  }
                />
              </Col>

              {(formik.values.occasion === 5 ||
                formik.values.occasion === '5' ||
                formik.values.occasion === '2' ||
                formik.values.occasion === 2) && (
                <>
                  <Col xs={12} sm={3}>
                    <Select
                      controlId={'delivery.hour'}
                      label={'Heures'}
                      name={'delivery.hour'}
                      value={formik.values.delivery.hour}
                      defaultValue={
                        (hours.Heures &&
                          hours.Heures.length > 0 &&
                          hours.Heures[0]) ||
                        ''
                      }
                      onChange={({target: {value}}) => {
                        formik.setFieldValue('delivery.hour', value)

                        if (formik.values.delivery.minutes === '') {
                          formik.setFieldValue('delivery.minutes', '00')
                        }
                      }}
                      options={
                        !isLoadingHours &&
                        hours.Heures &&
                        hours.Heures.map((item, index) => (
                          <>
                            {index === 0 ? <option value={''}>-</option> : ''}
                            <option
                              key={index}
                              value={item}
                              selected={formik.values.delivery.hour === item}>
                              {item}
                            </option>
                          </>
                        ))
                      }
                    />
                  </Col>
                  <Col xs={12} sm={3}>
                    <Select
                      controlId={'delivery.minutes'}
                      label={'Minutes'}
                      name={'delivery.minutes'}
                      value={formik.values.delivery.minutes}
                      onChange={formik.handleChange}
                      defaultValue={'00'}
                      options={
                        !isLoadingHours &&
                        hours.Minutes &&
                        hours.Minutes.map((item, index) => (
                          <>
                            {index === 0 ? <option value={''}>-</option> : ''}
                            <option
                              key={index}
                              value={item}
                              selected={
                                formik.values.delivery.minutes === item
                              }>
                              {item}
                            </option>
                          </>
                        ))
                      }
                      error={getIn(formik.errors, 'delivery.minutes')}
                    />
                  </Col>
                </>
              )}
            </Row>
            {(formik.values.occasion === 5 ||
              formik.values.occasion === '5') && (
              <Row>
                <Col>
                  <Select
                    controlId={'delivery.mourningplace'}
                    label={'Lieu de livraison'}
                    name={'delivery.mourningplace'}
                    defaultValue={''}
                    value={formik.values.delivery.mourningplace}
                    onChange={formik.handleChange}
                    options={
                      <>
                        <option value="">
                          Sélectionner un lieu de livraison
                        </option>
                        <option value="FUNERARIUM">FUNERARIUM</option>
                        <option value="EGLISE">EGLISE</option>
                        <option value="CIMETIERE">CIMETIERE</option>
                        <option value="AUTRE (à préciser)">
                          AUTRE (à préciser)
                        </option>
                      </>
                    }
                    error={
                      getIn(formik.touched, 'receiver.mourningplace') &&
                      getIn(formik.errors, 'delivery.mourningplace')
                    }
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Field
                  id={'delivery.information'}
                  name={'delivery.information'}
                  label={'Informations livreur'}
                  as={'textarea'}
                  rows={4}
                  value={formik.values.delivery.information}
                  onChange={(event) => {
                    const {value} = event.target;

                    if (value.length <= delivererInformationsLimit) {
                      setDelivererInformationsLength(value.length);
                      formik.setFieldValue('delivery.information', value);
                    }
                  }}
                  helpContent={`${delivererInformationsLimit - delivererInformationsLength}/${delivererInformationsLimit}`}
                />
              </Col>
            </Row>
          </>
        }
      />
    </>
  )
}

DeliveryForm.propTypes = {
  formik: PropTypes.object.isRequired,
  colXs: PropTypes.number,
  colSm: PropTypes.number,
}
